function NotFound() {
  return (
    <div className={"flex flex-col items-center"}>
      <div className={"flex flex-col items-center gap-2"}>
        <h1 className={"text-8xl font-bold"}>404</h1>
        <span className={"text-4xl"}>Not found :(</span>
      </div>
    </div>
  );
}

export default NotFound;
